import dynamic from 'next/dynamic'
import type { ComponentType, FC } from 'react'

import type { NextApplicationsNamesType } from '@Types/applications/nextApps'
import { ApplicationNames } from '@constants/applications/applicationNames'
import getAppConfig from '@services/config/config'

const FooterHst = dynamic(() => import('@widgets/footer/Footer'))
const FooterClassic = dynamic(() => import('@widgets/footer/FooterClassic'))

type Props = {
  customerSupportLink?: string
}

//TODO change after testing

const footerComponent: Record<NextApplicationsNamesType, ComponentType<Props>> = {
  [ApplicationNames.HST]: FooterHst,
  [ApplicationNames.RN_CLASSIC]: FooterClassic,
  [ApplicationNames.RN_MODERN]: FooterClassic,
  [ApplicationNames.RN_NEW]: FooterClassic,
}

const { publicRuntimeConfig } = getAppConfig()

export const FooterByAppName: FC<Props> = props => {
  const FooterComponent = footerComponent[publicRuntimeConfig.site as NextApplicationsNamesType]
  return <FooterComponent {...props} />
}
