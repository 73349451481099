import DatePicker from 'antd/es/date-picker'
import type { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import type { FC, MouseEvent, ReactNode } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { datePickerButtons } from '@base/DatePicker'
import ClearReturnDateButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/ClearReturnDateButton'
import MobileHeader from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/mobileHeader/MobileHeader'
import {
  datePikerLabelClassName,
  datePikerPopupClassName,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/classNames'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import {
  formHeightDesktop,
  searchFormSizes,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import SaveButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/saveButton/SaveButton'
import dateFormats from '@constants/dates/dateFormats'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import confirmButtonClickHandler from '@utils/datePicker/confirmButtonClickHandler'
import { isPastDay, today } from '@utils/dateTime/dateValidators'

import { CalenderIcon, DateInputGlobalStyles, DateLabel, WrapperDateInput } from './styles'

dayjs.extend(weekday)
dayjs.extend(localeData)

const ALLOW_EMPTY: RangePickerProps['allowEmpty'] = [false, true]

type Props = {
  close: () => void
  compactLabelView?: boolean
  datePickerProps?: RangePickerProps
  isOpened: boolean
  onClearReturnDate?: () => void
  open: () => void
  placeholder?: [string, string]
  position: 'bottom' | 'right'
}

export const DateInput: FC<Props> = ({
  close,
  compactLabelView,
  datePickerProps,
  isOpened,
  onClearReturnDate,
  open,
  placeholder,
  position,
}) => {
  const [activeInput, setActiveInput] = useState<string>()
  const { t } = useTranslation('Search form')
  const isMobile = useIsMobile()
  const isMobileTablet = useMediaQuery(mediaQueries.mobileTablet)
  const departureCaptionText = t('departureDate')
  const returnCaptionText = t('returnDate')
  const departurePlaceholder = `+${t('departure')}`
  const returnPlaceholder = `+ ${t('addReturn')}`

  const popupClassName = `${datePikerPopupClassName} ${position}`

  const onClickInput = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      const placeholder = (e.target as HTMLInputElement).placeholder

      if (activeInput === placeholder && isOpened) {
        close()
      } else {
        setActiveInput(placeholder)
      }
    },
    [isOpened]
  )

  const onOpenChange = useCallback((value: boolean) => (value ? open() : close()), [])

  const panelRender = useCallback(
    (panel: ReactNode) => (
      <>
        {isMobile && <MobileHeader closeAction={close} />}
        {panel}
      </>
    ),
    [isMobile, close]
  )

  const renderExtraFooter = useCallback(
    () => (
      <SaveButton
        onClick={e => {
          confirmButtonClickHandler(e)
          close()
        }}
      />
    ),
    []
  )

  const style = useMemo(
    () => ({
      height: `${isMobile ? searchFormSizes.fieldHeightMobile : searchFormSizes.fieldHeightDesktop}px`,
      padding: 0,
      width: '100%',
    }),
    [isMobile]
  )

  const popupStyle = useMemo(
    () => ({
      height: `${isMobile ? '100%' : `${formHeightDesktop + searchFormSizes.layoversHeightDesktop}px`}`,
      padding: 0,
      position: isMobile ? 'fixed' : 'absolute',
      width: `${
        isMobile
          ? '100%'
          : isMobileTablet
          ? `${searchFormSizes.popupWidthTablet}px`
          : `${searchFormSizes.popupWidthDesktop}px`
      }`,
      ...datePickerProps?.popupStyle,
    }),
    [isMobile, isMobileTablet, datePickerProps?.popupStyle]
  )

  const suffixIcon = useMemo(() => (compactLabelView ? null : <CalenderIcon />), [compactLabelView])

  return (
    <WrapperDateInput>
      <DateLabel
        $compactLabelView={compactLabelView}
        $departureLabel={departureCaptionText}
        $isPopoverOpened={isOpened}
        $returnLabel={returnCaptionText}
        className={datePikerLabelClassName}
        name={SearchFormKeys.date}
        rules={[{ required: true }]}
      >
        <DatePicker.RangePicker
          {...datePickerButtons}
          {...(datePickerProps || {})}
          allowClear={false}
          allowEmpty={ALLOW_EMPTY}
          autoFocus={true}
          clearIcon={false}
          disabledDate={isPastDay}
          format={dateFormats['01 Jan 2023']}
          getPopupContainer={node => node}
          inputReadOnly={true}
          minDate={today}
          needConfirm={isMobile}
          onClick={onClickInput}
          onOpenChange={onOpenChange}
          open={isOpened}
          panelRender={panelRender}
          placeholder={placeholder ? placeholder : [departurePlaceholder, returnPlaceholder]}
          placement="bottomLeft"
          popupClassName={popupClassName}
          popupStyle={popupStyle as RangePickerProps['popupStyle']}
          renderExtraFooter={renderExtraFooter}
          separator={null}
          showNow={false}
          style={style}
          suffixIcon={suffixIcon}
          variant="borderless"
        />
      </DateLabel>
      {onClearReturnDate && <ClearReturnDateButton onClear={onClearReturnDate} />}
      <DateInputGlobalStyles />
    </WrapperDateInput>
  )
}
