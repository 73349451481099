import type { FC } from 'react'
import { memo } from 'react'
import styled from 'styled-components'

import { selectWithLayover } from '@redux/features/search/search.selectors'
import { useAppSelector } from '@redux/hooks'

import { SwitcherWrapper } from '@base/Toggler/styles'
import { StyledButton } from '@base/buttons/Button/styles'
import Background from '@components/mainPage/mainBlock/searchTrains/search/background/Background'
import { DateLabel } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput'
import SearchForm from '@components/mainPage/mainBlock/searchTrains/search/searchForm/SearchForm'
import { searchFormId } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import { searchFormSizes } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import { getSearchFormHeight } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/getSearchFormHeight'
import { fieldsStyles } from '@components/mainPage/mainBlock/searchTrains/search/styles'

import { PassengersLabelWrapper } from './searchForm/passengers/components/PassengersLabel/styles'

const Search: FC = () => {
  const withLayover = useAppSelector(selectWithLayover)

  return (
    <SearchWrapper $isLayover={withLayover}>
      <SearchForm />
      <Background />
    </SearchWrapper>
  )
}

const SearchWrapper = styled.div<{ $isLayover: boolean }>`
  margin: ${searchFormSizes.marginBlockDesktop}px ${p => (p.$isLayover ? '0 0 0' : '0')}px;
  z-index: 2;
  grid-area: form;
  width: ${searchFormSizes.formWidthDesktop}px;
  position: relative;
  border-radius: ${p => p.theme.borderRadius.default};
  height: ${p => getSearchFormHeight(p.$isLayover)}px;
  background: ${p => p.theme.colors.backgroundBlock};

  #${searchFormId} {
    ${fieldsStyles};
    z-index: 1;
    position: relative;
    width: inherit;
    border-radius: ${p => p.theme.borderRadius.default};
    padding-bottom: ${searchFormSizes.paddingBottomDesktop}px;

    & > ${StyledButton}, ${SwitcherWrapper} {
      width: calc(100% - 48px);
    }

    ${SwitcherWrapper} {
      margin: ${(searchFormSizes.switcherHeightDesktop - 48) / 2}px 24px;
    }

    & > ${StyledButton}, ${PassengersLabelWrapper}, ${DateLabel} {
      margin-inline: 24px;
    }
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    width: ${searchFormSizes.formWidthTablet}px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    height: ${p => getSearchFormHeight(p.$isLayover, true)}px;
    width: calc(100% - ${p => p.theme.padding.mobileBlock * 2}px);
    margin: 0 auto;

    #${searchFormId} {
      padding-bottom: ${searchFormSizes.paddingBottomMobile}px;

      ${SwitcherWrapper} {
        margin: ${(searchFormSizes.switcherHeightMobile - 48) / 2}px 24px;
      }
    }
  }

  ${p => p.theme.mediaQueries.mobileM} {
    width: 100%;
  }
`

export default memo(Search)
