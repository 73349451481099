import {
  layoversListClassName,
  passengersLabelClassName,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/classNames'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'

export const openPassengersForm = () =>
  (document.querySelector(`.${passengersLabelClassName}`) as HTMLDivElement)?.click()

export const focusDepartureStation = () =>
  (document.querySelector(`#${SearchFormKeys.departure}`) as HTMLInputElement)?.focus()

export const focusArrivalStation = () =>
  (document.querySelector(`#${SearchFormKeys.arrival}`) as HTMLInputElement)?.focus()

export const selectFirstLayover = () => {
  const layoversList = document.querySelector(`.${layoversListClassName}`) as HTMLInputElement
  const firstElement = layoversList?.firstElementChild as HTMLElement
  firstElement?.click()
}
